.header_container{
    width: 100%;
    min-height: 324px;
    max-width: 1920px;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 20;
    background-image: url("../../Assets/HeaderImages/first_bg.png");
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width:1440px) {
        padding: 20px;
    }
    // @media (max-width:980px) {
    //     max-width: 757px;
    //     padding: 0;
    // }
    // @media (max-width:767px) {
    //     max-width: 620px;
    // }
    @media (max-width:660px) {
        padding-bottom: 30px;
        border-bottom-right-radius: 50px;
    }
    @media (max-width:540px) {
        padding: 0;
        padding: 25px;
    }
    .custom_modal{
        background-color: rgba(0,0,0,.5);
        width: 100%;
        max-width: 1920px;
        min-height: 228px;
        height: auto;
        position: absolute;
        top: 0;
        transition: all ease-in;
        top: -250px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width:540px) {
            background-color: rgba(0,0,0,.9);
            min-height: 258px;
        }
        .modal_content{
            width: 100%;
            max-width: 705px;
            @media (max-width:540px) {
                width: 90%;
            }
            .first{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
             
                .first_label{
                    font-weight: 700;
                    color: #F0522B;
                    font-size: 18px;
                }
                .second_label{
                        height: 24px;
                        width: 24px;
                        color: #FFFFFF;
                }
            }
            .second{
                width: 100%;
                height: 50px;
                background-color: #FFFFFF;
                border-radius: 8px;
                margin-top: 10px;
            }

            .result_holder{
                background-color: white;
                width: 100%;
                height: auto;
                // padding: 20px;
                .container_1{
                    display: flex;
                    align-items: center;
                    padding: 5px 20px;
                    justify-content: flex-start;
                    .com_logo {
                        background-size: contain;
                        height: 30px;
                        background-position: right;
                        background-repeat: no-repeat;
                    }
                    .img_1{
                        max-width: 30px;
                        width: 30px;
                        max-height: 100px;
                        margin-right: 15px;
                        cursor: pointer;
                        @media (max-width:540px) {
                            width: 40%;
                        }
                    }
                    .res_name{
                        color: rgb(240, 82, 43);
                        text-transform: capitalize;
                        font-size: 25px;
                        font-family:'Bebas Neue', sans-serif ;
                        cursor: pointer;
                        @media (max-width:540px) {
                            font-size: 20px;
                        }
                      
                    }
                }
            }
        }
    }

    .upper_part{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 20px;
        padding-top: 50px;
        width: 100%;
        flex-wrap: wrap;
        max-width: 1440px;
        padding-left: 40px;
        @media (max-width:767px) {
            padding-left: 10px;
        
        }
        @media (max-width:660px) {
            padding-left: 0;
        
        }
        @media (max-width:540px) {
            padding: 0;
            padding-top: 20px;
        }
        .icons_container{
             display: flex;
             align-items: center;
             @media (max-width:660px) {
              width: 100%;
              justify-content: space-between;   
          
            }
            .burger_icon{
                width: 56px;
                height: 40px;
                color:#FFFFFF;
                display: none;
                
            @media (max-width:660px) {
                display: block;   
            }
            }

            .search_icon{
                font-size: 30px;
                color: white;
                display: none;
                @media (max-width:660px) {
                    display: block;   
                }
            }
          
            .img_container{
                height: 87px;
                width: 201.71px;
                // margin-left: -30px;
                @media (max-width:767px) {
                    width: 120px;
                    height: 50.29px;
                }
                @media (max-width:660px) {
                    height: 70px;
                    width: 162.29px;
                }
                img{
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .list_options{
            display: flex;
            align-items: center;  
            cursor: pointer;  
    
            @media (max-width:660px) {
                display: none;   
            }
    
            .first{
                font-weight: 700;
                font-size: 16px;
                color: #FFFFFF;
                // color:#F0522B;
                cursor: pointer;  
                margin-right: 30px;
            }
            .second{
                font-weight: 700;
                font-size: 16px;
                color:#FFFFFF;
                margin-right: 30px;
                cursor: pointer;  
            }
            .third{
                font-weight: 700;
                font-size: 16px;
                cursor: pointer;  
                color:#FFFFFF;
                border:1px solid white;
                border-radius: 8px;
                padding:8px 48px;
            }
        }

    }
   
    .label_container{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        max-width: 1440px;
        margin-top: 20px;
        // padding-left: 20px;
        padding-right: 50px;
        // @media (max-width:480px) {
        //     padding-right: 30px;
        // }
        .first{
            font-size: 64px;
            font-family: "Bebas Neue", sans-serif;
            color:#FFFFFF;

            @media (max-width:660px) {
                font-size: 64px;
                line-height: 76.8px;
            }
            
        }
        .first_1{
            font-size: 128px;
            font-family: "Bebas Neue", sans-serif;
            color:#FFFFFF;
            @media (max-width:767px) {
                font-size: 70px;
              
            }
            @media (max-width:660px) {
                font-size: 64px;
                line-height: 76.8px;
            }
            
        }
    }
}