.main_banner{
    width: 100%;
    background-image: url("../../Assets/partners_logos/Rectangle240.png");
    height: 980px;
    background-color: rgba(0,0,0,.7);
    position: relative;
    border-bottom-right-radius: 120px;
    @media (max-width:1100px) {
        border-radius: 0px;
        height: 700px;
    }
    @media (max-width:1080px) {
        border-radius: 0px;
        height: 900px;
    }
    @media (max-width:640px) {
        border-radius: 0px;
        height: 1000px;
    }
    @media (max-width:575px) {
        height: 900px;
    }
    @media (max-width:460px) {
        height: 950px;
    }
    @media (max-width:430px) {
        height: 1000px;
    }
    @media (max-width:400px) {
        height: 1100px;
    }
    @media (max-width:360px) {
        height: 940px;
    }
    @media (max-width:380px) {
        height: 950px;
    }
    .left{
        width: 50%;
        display: flex;
        justify-content: flex-end;
        height: 100%;
        align-items: center;
        @media (max-width:1800px) {
            width: 40%;
        }
        @media(max-width:1580px){
            width: 35%;
        }
        @media (max-width:1280px) {
            width: 20%;
        } 
        @media (max-width:1100px) {
            align-items: flex-end;
        }
        @media (max-width:1080px) {
            width: 50%;
            align-items: center;
        } 
        @media (max-width:840px) {
            width: 50%;
        } 
        @media (max-width:767px) {
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
        }
      
        
        .left_content{
            width: 845px;
            height: 462px;
            margin-left: 20px;
            margin-right: -200px;
            margin-top: -80px;
            @media(max-width:1580px){
                margin-right: -420px;
            } 
            @media (max-width:1080px) {
              margin-top: 10px;
            } 
              @media (max-width:840px) {
                padding: 40px;
               
             } 
             @media (max-width:767px) {
                margin-top: 140px;
                width: 100%;
                margin-left: 0;
             }
             
             @media (max-width:480px) {
                padding: 40px;
                
             }
             @media (max-width:380px) {
                padding: 20px;
            }
            .first{
                color: #F0522B;
                font-weight: 600;
                font-size: 32px;
            }
            .second{
                font-size: 128px;
                color: white;
                font-family: "Bebas Neue", sans-serif;
                line-height: 120px;
                @media(max-width:1580px){
                  font-size: 90px;
                  line-height: 90px;
                }
                @media(max-width:640px){
                    font-size: 60px;
                    line-height: 60px;
                  }
                  @media (max-width:380px) {
                    font-size: 50px;
                    line-height: 50px;
                }
            }
            .third{
                font-weight: 400;
                font-size: 24px;
                line-height: 30px;
                color: #F9F8F8;
            }
        }
    }
    &::after{
        content: "";
        position: absolute;
        background-image: url("../../Assets/partners_logos/241.png");
        height: 818px;
        background-repeat: no-repeat;
        width: 1000px;
        background-size: contain;
        z-index: 4;
        right: 0;
        bottom: 100px;
        @media(max-width:1580px){
            width:700px;
            height: 700px;
            background-size: contain;
        }
        @media (max-width:1400px) {
            width: 600px;
        } 
        // @media (max-width:1280px) {
        //     width: 800px;
        // } 
        @media (max-width:1100px) {
            bottom: -190px;
        }
        @media (max-width:1080px) {
            // display: none;
            bottom: -340px;
            width: 500px;
            height: 700px;
            
        } 
        // @media (max-width:980px) {
        //     bottom: -300px;
           
        // }
        @media (max-width:767px) {
            //width:100%;
        }
        @media (max-width:640px) {
            bottom: -300px;
            
        }
        @media (max-width:575px) {
            bottom: -420px;
            width: 400px;
        }
        @media (max-width:460px) {
            bottom: -500px;
            width: 320px;
        }
        @media (max-width:360px) {
            bottom: -480px;
            width: 350px;
        }
        @media (max-width:280px) {
            bottom: -500px;
        }
    }
}



.first_layer{
    width: 100%;
    background-color: white; //EDEDED
    padding-top: 30px;
    padding-bottom: 50px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99;
    @media (max-width:980px) {
        padding-bottom: 10px;
    }
    
    .label_container{
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        .newlabel{
            text-transform: uppercase;
            font-size: 64px;
            line-height: 64px;
            font-family: "Bebas Neue", sans-serif;
            @media (max-width:575px) {
                font-size: 40px;
                line-height: 45px;
            }
        }
    }
    
    .partner_logo_container {
        display: flex;
        flex-wrap: nowrap;
        margin-top: 40px;
        max-width: 1400px;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
        padding-bottom: 40px;
        justify-content: center;
        align-items:center;
        position: relative;
        @media (max-width:1440px) {
            width: 100%;
        }
        @media (max-width:980px) {
            margin-top: 10px;
        }
        .sub_con {
            // padding: 20px;
            // min-width: 25%; /* Each item will take up 25% of the container's width */
            // max-width: 400px; /* Limit maximum width to 400px */
            height: 250px;
            width: 100%;
            // background-color: red;
            border: 0.5px solid rgba(230, 224, 224,.4);
            // border-right: 0;
            // border-top: 0;
            border-top: 0;
            display: flex;
            justify-content: space-between;
            // align-items: sp;
            flex-direction: column;
            .partner_logo:hover{
                cursor: pointer;
            }
        
        
          }
          
          .sub_con img {
            height: 70px;
            max-width: 175px;
            border-radius: 10px;
            cursor: pointer;
            
            @media (max-width:840px) {
                width: 100%;
            }
          }
      }
      
      
      
      

}


.homepage_second_container{
    display:flex;
    align-items:center;
    width: 100%;
    flex-direction: column;
    margin-bottom: 300px;

    @media (max-width:540px) {
        margin-bottom: 200px;
    }

    .headlines{
        display:flex;
        align-items:center;
        width: 60%;
        justify-content: center;
        flex-direction: column;
        padding-top: 40px;
        @media (max-width:980px) {
            padding-top: 20px;
            padding: 0 10px;
            width: 100%;
        }
        @media (max-width:540px) {
            width: 100%;
            padding-top: 10px;
        }
        .first{
            color:#F0522B;
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            text-align: center;
            @media (max-width:980px) {
                font-size: 25px;
                line-height: 30px;
            }
            
        }
        .second{
            color:"#F0522B";
            font-size: 64px;
            line-height: 76px;
            text-align: center;
            font-family: "Bebas Neue", sans-serif;
            @media (max-width:980px) {
                font-size: 40px;
                line-height: 45px;
            }
            @media (max-width:540px) {
                    margin-top: 10px;
            }
        }
        .third{
            color:#525C60;
            font-weight: 400;
            margin-top: 10px;
            font-size: 24px;
            text-align: center;
            line-height: 39px;
        }
    }
    .section_container{
        display: flex;
        align-items: center;
        width: 100%;

        @media (max-width:1240px) {
            margin-top: 40px;
            flex-direction: column;
        }
        .left_side{
            width: 50%;
            position:relative;
            min-height: 690px;
            @media (max-width:1240px) {
                width: 100%;
             
            }
            .first_level{
                z-index: 2;
                background-image: url("../../Assets/partners_logos/Rectangle_292.png");
                width: 650px;
                height: 777px;
                position:absolute;
                z-index: 3;
                border-radius: 24px;
                right: 0;
                top: 70px;

                @media (max-width:1240px) {
                    left: 250px;
                }
                @media (max-width:840px) {
                    left:10%;
                    width: 90%;
                    background-repeat: no-repeat;
                }
                @media (max-width:430px) {
                    height: 500px;
                }
            }
            &::after {
                content: "";
                position: absolute;
                width: 537px;
                height: 595px;
                background-color: #E9E9E9;
                border-top-right-radius: 24px;
                border-bottom-right-radius: 24px;
                @media (max-width:1400px) {
                    width: 400px;
                }
                @media (max-width:767px) {
                    width: 40%;
                }
                @media (max-width:540px) {
                    height: 470px;
                }
                @media (max-width:430px) {
                    height: 400px;
                }
                // Specify styles for before and after pseudo-elements
            }
            &::before {
                content: "";
                position: absolute;
                width: 395px;
                height: 727px;
                background-color: #210002;
                border-radius: 24px;
                top: 190px;
                left: 100px;
                @media (max-width:1400px) {
                    width: 300px;
                    height: 550px;
                }
                @media (max-width:980px) {
                    left: 0;
                }
                @media (max-width:767px) {
                    width: 40%;
                    border-bottom-left-radius: 0px;
                }
                @media (max-width:430px) {
                    height: 450px;
                }
                // Specify styles for before and after pseudo-elements
            }
            
        }
        .right_side{
            // background-color: rgb(29, 135, 211);
            width: 50%;
            padding: 20px;
            min-height: 500px;
            display: flex;
            padding-left: 50px;
            padding-top: 70px;
            flex-direction: column;
            align-items: flex-start;
            padding-right:170px;
            @media (max-width:1440px) {
                padding: 0;
                padding-left: 40px;
            }
            @media (max-width:1240px) {
                padding-top: 290px;
            }
            @media (max-width:980px) {
                width: 100%;
                align-items: center;
                padding: 0;
                padding-top: 70px;
                margin-top: 150px;
            }
            @media (max-width:430px) {
               padding-top: 0;
               margin-top: 10px;
            }

            .listing{
                display: flex;
                padding-right:40px;
                margin-bottom: 30px;
                @media (max-width:980px) {
                    width: 70%;
                    justify-content: center;
                    padding-right:0;
                 
                }
                @media (max-width:575px){
                    width: 90%;
                    
                }
                .listing_img{
                    width: 64px;
                    height: 64px;
                    margin-right: 10px;
                }
                .listing_desc{
                    display: flex;
                    flex-direction: column;

                    .label{
                        color: #2B2B2B;
                        font-weight: 800;
                        font-size: 25px;
                    }
                    .desc{
                        color: #525C60;
                        font-weight: 400;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .section_container2{
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 190px;
        @media (max-width:1240px) {
            margin-top: 40px;
            flex-direction: column-reverse;
            margin-top: 200px;
           
        }
        @media (max-width:575px) {
                margin-top: 120px;
        }
        .right_side{
            // background-color: rgb(179, 255, 0);
            width: 50%;
            position:relative;
            min-height: 690px;

            @media (max-width:1240px) {
                width: 100%;
            }
            .first_level{
                background-image: url("../../Assets/partners_logos/Rectangle_293.png");
                width: 650px;
                height: 777px;
                width: 100%;
                max-width: 650px;
                position:absolute;
                z-index: 4;
                border-radius: 24px;
                left: 140px;
                top: 0;
                background-size: cover;
                background-repeat: no-repeat;
                @media (max-width:1240px) {
                    width: 55%;
                    left: 20%;
                    
                }
                @media (max-width:980px) {
                    width: 70%;
                    left: 10%;
                    
                }
                @media (max-width:840px) {
                    width: 90%;
                    left: 40px;
                }
                @media (max-width:430px) {
                    height: 500px;
                }
            } &::after {
                content: "";
                position: absolute;
                width: 537px;
                height: 595px;
                background-color: #E9E9E9;
                border-radius: 24px;
                top: -110px;
                right: 0;
                @media (max-width:980px) {
                    width: 50%;
                    
                }
                @media (max-width:840px) {
                    right: 0;
                }
                
                // Specify styles for before and after pseudo-elements
            } &::before{
                // content: "";
                background-color: #210002;
                bottom: 100px;
                width:179px;    
                height: 240px;
                z-index: 5;
                right: 110px;
                border-radius: 24px;
                position:absolute;
                @media (max-width:1440px) {
                    // display: none;

                    right: 170px;
                }
                @media (max-width:980px) {
                    right: 100px;
                    
                }
                @media (max-width:840px) {
                    right: 0;
                }
                @media (max-width:430px) {
                    height: 200px;
                }
            }
            
        }
        .left_side{
            // background-color: rgb(29, 135, 211);
            width: 50%;
            padding: 20px;
            min-height: 500px;
            display: flex;
            padding-right: 50px;
            padding-left: 190px;
            padding-top: 70px;
            flex-direction: column;
            align-items: flex-start;
            @media (max-width:1440px) {
                padding: 0;
                padding-left: 50px;
                padding-top: 70px;
            }
            @media (max-width:1440px) {
                // display: none;

                padding-top: 290px;
            }
            @media (max-width:980px) {
                width: 100%;
                padding: 0;
                padding-top: 70px;
                margin-top: 150px;
                align-items: center;
            }
            @media (max-width:575px) {
                margin-top: 70px;
            }
           @media (max-width:430px) {
                margin-top: 10px;
                padding: 0;
           }    

            .listing{
                display: flex;
                padding-right:40px;
                margin-bottom: 30px;
                // background-color: green;
                @media (max-width:980px) {
                    width: 70%;
                }
                @media (max-width:575px) {
                    width: 90%;
                    padding-right: 0;
                }
                .listing_img{
                    width: 64px;
                    height: 64px;
                    margin-right: 10px;
                }
                .listing_desc{
                    display: flex;
                    flex-direction: column;

                    .label{
                        color: #2B2B2B;
                        font-weight: 800;
                        font-size: 25px;
                    }
                    .desc{
                        color: #525C60;
                        font-weight: 400;
                        font-size: 18px;
                    }
                }
            }
        }
    }
   
}

.homepage_third_container{
    width: 100%;
    max-width:1950px;
    min-height: 1086px;
    margin-top: -100px;
    border-top-left-radius: 25px;
    background-image: url("../../Assets/partners_logos/bg3.png");
    position: relative;

    @media (max-width:980px) {
        background-color: black;
        background-image:none;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height:1086px ;
        // height: 1500px;
        // background-color: red;
    }
    &::after{
        content: "";
        position:absolute;
        top:0;
        right:0;
        background-image: url("../../Assets/partners_logos/overlay1.png");
        z-index: 10;
        width: 500px;
        top:-320px;
        height: 700px;
        background-size: contain;
        background-size: cover;
        @media (max-width:980px) {
            width: 400px;   
           height: 600px;
        }
        @media (max-width:540px) {
            width: 50%;   
            height: 600px;
            top: -200px;
        }
    }
    &::before{
        content: "";
        position:absolute;
        left:0;
        background-image: url("../../Assets/partners_logos/overlay2.png");
        bottom:0;
        z-index: 1;
        width: 500px;
        height: 500px;
        background-size: contain;
        background-repeat: no-repeat;
        @media (max-width:540px) {
            width: 50%;
            height: 400px;
            background-size: cover;
        }
    }

    // .overlay_first{
       
    // }
    
    // .overlay_second{
       
    // }
   
    .section_1{
        width: 744px;
        height: 853px;
        border-radius: 10px;
        background-color: rgba(0,0,0,.5);
        padding: 50px;
        margin-left:200px;
        margin-top: 70px;
        z-index: 5;
        position:relative;

        @media (max-width:980px) {
            margin-left: 0px;
            margin-top: 0px;
            padding:20px;
            padding-top: 0;
        
        }
        .first{
            font-size: 64px;
            font-weight: 400;
            color:#FFFFFF;
            line-height: 76px;
            font-family: "Bebas Neue", sans-serif;
            margin-bottom: 15px;
        }
        .second{
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 10px;
            color: #F0522B;
        }
        .label{
            font-size: 32px;
            font-weight: 400;
            color:#FFFFFF;
            margin-top: 20px;
            font-family: "Bebas Neue", sans-serif;
        }
        .desc{
            font-size: 18px;
            font-weight: 400;
            color:#FFFFFF;
            opacity: .5;
        }
    }   

}
.custom-arrow_custom-arrow-left{
    padding: 10px;
    width: 50px;
}

.custom-arrow_custom-arrow-right{
    padding: 10px;
    width: 50px;
}

.hzTWok{
    color: #F0522B !important;
 

    @media (max-width:640px) {
        font-size: 12px !important;
        width: 25px !important;
        height: 25px !important;
        min-width: 25px !important;
        line-height: 1.7rem !important;
    }

    &:hover {
        // Styles to apply on hover
        // For example, change background color
        background-color: #F0522B !important; // Orange color
        color: white !important;
    }
}
.iRumey{
    @media (max-width:640px) {
        font-size: 12px !important;
        width: 25px !important;
        height: 25px !important;
        min-width: 25px !important;
        line-height: 1.6rem !important;
    }

}
.hFlVyB{
    background-color: #F0522B !important;
    box-shadow:0 0 1px 3px #F0522B !important;

}


// .rec-arrow {
    
//     // background-color: var(--blue) !important;
//     transition: all 0.3s ease;
   
//     color: white !important;
  
//     line-height: 1.7rem !important;
   
// }

// responsive
// @media  screen and (max-width: 1920px) {}
// @media  screen and (max-width: 1440px) {}
// @media  screen and (max-width: 1280px) {}
// @media  screen and (max-width: 1100px) {}
// @media  screen and (max-width: 1080px) {}
// @media  screen and (max-width: 980px) {}
// @media  screen and (max-width: 767px) {}
// @media  screen and (max-width: 640px) {}
// @media  screen and (max-width: 575px) {}
// @media  screen and (max-width: 440px) {}
// @media  screen and (max-width: 320px) {}
// @media  screen and (max-width: 280px) {}