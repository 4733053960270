.partner_logo{
    width: 120px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
}
.promotion_page_container{
    max-width: 1400px;
    width: 100%;
    @media (max-width:980px) {
        max-width: 757px;
    }
    .content_page_container{
        max-width: 1440px;
        min-width: 1440px;
        width: 100%;
        // min-width: 1000px;
        height: auto;
        display: flex;
        // align-items: center;
        padding-top: 70px;
        padding-bottom: 50px;
        @media (max-width:1440px) {
            padding: 40px;
            min-width: 100%;
        }
        @media (max-width:980px) {
            flex-direction: row;
            flex-wrap: wrap;
            padding: 40px 0;
        }
        // @media (max-width:920px) {
        //    flex-direction: column;
        // }
        @media (max-width:640px) {
            padding: 25px;
        }
        @media (max-width:640px) {
            padding: 10px;
        }
        .left_side{
            width:  50%;
            max-width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
         
            @media (max-width:980px) {
                width: 100%;
                max-width: 100%;
                padding: 10px 10px;
            }
            @media (max-width:920px) {
                width: 100%;
                max-width: 100%;
                margin-bottom: 40px;
            }
        
            .img_container{
                max-width: 363px;
                // height: 80px;
                
                // @media (max-width:540px) {
                //     width: 90%;
                //     height: 90%;
                //     margin-top: 20px;
                // }
                // img{
                //     height: 100%;
                //     width: 100%;
                // }
            }
            
            .restaurant_label{
                font-family: "Bebas Neue", sans-serif;
                font-size: 64px;
                line-height: 76px;
                margin-top: 20px;
                @media (max-width:980px) {
                    font-size: 50px;
                    line-height: 55px
                }
            }

            .opening_time{
                display: flex;
                align-items: center;

                .first{
                    margin-right: 10px;
                }   
                .second{
                    font-size: 16px;
                    color: #2B2B2B;
                }
            }

            .restaurant_desc{
                margin-top: 20px;
                
                padding-right: 50px;
                p{
                    // font-family: "Bebas Neue", sans-serif;
                }
                @media (max-width:640px) {
                    padding-right: 0;
                }

                .first{
                    font-weight: 400;
                    font-size: 24px;
                    line-height:29px;
                    margin-bottom: 20px;
                    color:#525C60;
                    .table{
                        overflow: hidden;
                    }
                }
                .second{
                    font-weight: 400;
                    font-size: 24px;
                    line-height:29px;
                    margin-bottom: 20px;
                    color:#525C60;
                }
                .third{
                    font-weight: 400;
                    font-size: 24px;
                    line-height:29px;
                    margin-bottom:  40px;
                    color:#525C60;
                }
            }


            .img_containers{
                display: flex;
                // flex-wrap: wrap;
                justify-content: flex-start;
                margin: 1%;
                overflow: auto;
                flex-direction: row;
                width: 100%;
                // height: 240px;
                // max-height: 400px;
                overflow: auto;
                flex-wrap: wrap;
                @media (max-width:640px) {
                    // height: 150px;
                    flex-wrap: wrap;
                }
                @media (max-width:360px) {
                    justify-content: center;
                }
                .first_{
                    width: 100%;
                    height: 200px;
                    max-width: 200px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    margin: 5px;
                    // max-height: 180px;
                    align-items: center;
                    display: flex;
                    cursor: pointer;
                    @media (max-width:640px) {
                        width: 100%;
                        //margin-right: 0;
                        
                    }
                    @media (max-width:460px) {
                    
                        max-width: 150px;
                        height: 150px;
                        // height: 350px;
                    }
                    @media (max-width:360px) {
                        max-width: 100%;
                        height: 200px;
                    }
                }
                @media (max-width:840px) {
                    width: 100%;
                    padding-right: 0;
                }
                img{
                      width: 100%;
                    //   max-height: 150px;
                      margin-bottom: 20px;
                      @media (max-width:640px) {
                        // max-height: 100px;
                      }
                }
            }

            .btn_details{
                @media (max-width:540px) {
                    width: 100%;
                }
                .reservation_btn{
                    background-color: #F0522B;
                    color: white;
                    width: 306px;
                    margin-top: 20px;
                    padding: 10px;
                    @media (max-width:540px) {
                        width: 100%;
                    }
                }
                
            }
        
            .right_side{
                width: 50%;
                flex-direction: column;
                min-height: 858px;
                height: auto;
                max-width: 576px;
                background-color: #F2F2F2;
                padding: 50px;
                border-radius: 30px;
                margin-left: 70px;
                display: none;
    
                @media (max-width:920px) {
                    width: 100%;
                    max-width: 100%;
                    margin-left: 0;
                    min-height: 0;
                    height: auto;
                    margin-top: 40px;
                }

                @media (max-width:540px) {
                    display: flex;
                    padding: 20px;
                    display: none;
                }

                .first{
                    color: #F0522B;
                    font-weight: 700;
                    line-height: 29px;
                    font-size: 24px;
                    margin-bottom: 20px;
                }
                .second{    
                    color: #2B2B2B;
                    font-family: "Bebas Neue", sans-serif;
                    line-height: 50px;
                    font-size: 60px;
                    margin-bottom: 20px;
                }
                .third{
                    color: #2B2B2B;
                    font-weight: 400;
                    line-height: 24px;
                    font-size: 18px;
                    margin-bottom: 20px;
                }
    
                .address_container{
                    display: flex;
                    width: 292px;
                    // padding-right: 70px;
                    margin-bottom: 20px;
                    .location_icon{
                        height: 24px;
                        width: 24px;
                        color: #F0522B;
                        margin-right: 5px;
                    }
    
                    .address_desc{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start ;

                         .reservation_btn{
                            background-color: #F0522B;
                            color: white;
                            width: 306px;
                            margin-top: 20px;
                            padding: 10px;
                            @media (max-width:540px) {
                                width: 100%;
                            }
                        }
                        
                    }
                }
    
            }
        
        }
        .right_side{
            width: 50%;
            display: flex;
            flex-direction: column;
            // height: 858px;
            max-width: 576px;
            background-color: #F2F2F2;
            padding: 50px;
            border-radius: 30px;
            margin-left: 70px;
            align-self: flex-start;

            // @media (max-width:540px) {
            //     display: none;
             
            // }
            @media (max-width:980px) {
                width: 100%;
                margin-left: 0;
                max-width: 100%;
                padding: 20px;
            }

            @media (max-width:920px) {
                width: 100%;
                max-width: 100%;
                margin-left: 0;
                margin-top: 40px;
            }
            .first{
                color: #F0522B;
                font-weight: 700;
                line-height: 29px;
                font-size: 24px;
                margin-bottom: 20px;
            }
            .second{    
                color: #2B2B2B;
                font-family: "Bebas Neue", sans-serif;
                line-height: 50px;
                font-size: 60px;
                margin-bottom: 20px;
            }
            .third{
                color: #2B2B2B;
                font-weight: 400;
                line-height: 24px;
                font-size: 18px;
                margin-bottom: 20px;
            }

            .address_container{
                display: flex;
                width: 292px;
                // padding-right: 70px;
                margin-bottom: 20px;
                .location_icon{
                    height: 24px;
                    width: 24px;
                    color: #F0522B;
                    margin-right: 5px;
                }

                .address_desc{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start ;
                    .reservation_btn{
                        background-color: #F0522B;
                        color: white;
                        width: 306px;
                        margin-top: 10px;
                        padding: 10px;
                        @media (max-width:540px) {
                            width: 100%;
                        }
                    }
                    
                }
            }

        }
        
    }


    
}

.promotion_offer_container{
    max-width: 1440px;
    width: 100%;
    background-color: #EDEDED;
    padding: 100px;
   
    @media (max-width:1440px) {
        padding: 40px;
    }
    @media (max-width:540px) {
        padding: 25px;
    }
    .no_promo{
            text-align: center;
            font-size: 80px;
            font-family: "Bebas Neue", sans-serif;
            @media (max-width:640px) {
                font-size: 40px;
            }
    }
    .first_layer_{
        // background-color: red;
        .first{
            font-family: "Bebas Neue", sans-serif;
            line-height: 115px;
            font-size: 96px;
        }
        .second{
            font-size: 32px;
            font-weight: 400;
            line-height: 39px;
            color:#2B2B2B;
            margin-bottom: 20px;
        }
    }
    .second_layer{

        display: flex;
        align-items: center;
        flex-wrap: wrap;

      

        .left{
            width: 40%;
            min-height: 290px;
            height: auto;
            border-right: 1px solid #D9D9D9;
            @media (max-width:840px) {
                // padding-left: 20px;
                width: 50%;
            }
            @media (max-width:640px) {
                // padding-left: 20px;
                width: 100%;
                border-right: none;
            }

            .first_{
                font-family: "Bebas Neue", sans-serif;
                line-height: 115px;
                font-size: 96px;
            }
            .second_{
                font-size: 32px;
                font-weight: 400;
                line-height: 39px;
                color:#F0522B;
                margin-bottom: 20px;
            }
            

            .first{
                font-weight: 700;
                font-size: 32px;
                line-height: 39px;
            }

            .second{
                margin-top: 10px;
                margin-bottom: 10px;
                font-weight: 400;
                font-size: 24px;
                line-height: 29.26px;  
                color: #525C60;
            }

            .third{
                font-weight: 400;
                font-size: 24px;
                line-height: 29.26px;
                margin-bottom: 5px;  
                color: #525C60
            }

        }
        .right{
            width: 60%;
            min-height: 290px;
            height: auto;
            padding-left: 150px;

            @media (max-width:840px) {
                padding-left: 20px;
                width: 50%;
            }
            @media (max-width:640px) {
                padding-left: 0;
                width: 100%;
                border-right: none;
            }
            @media (max-width:640px) {
                // padding-left: 20px;
                margin-top: 25px;
            }
            .first{
                font-weight: 400;
                font-size: 24px;
                line-height: 29.26px;
                color: #525C60;
                margin-bottom: 30px;
            }
            .second{
                font-weight: 400;
                font-size: 24px;
                line-height: 29.26px;
                color: #525C60;
            }
        }
    }

    .third_layer{
        width: 100%;
        max-width: 721px;
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        min-height: 154px;
        background-color: #F5D3CB;
        border-radius: 1px solid gray;
        border-radius: 10px;
        margin-top: 50px;
        .left_side{
            width: 50%;
            height: 100%;
            min-height: 154px;
            border-right: 1px solid lightgray;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width:640px) {
                // padding-left: 20px;
                width: 100%;
                border-bottom: 1px solid white;
            }
            div{
              
                .first{
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 29px;
                    color:#2B2B2B;
                    margin-bottom: 20px;
                }
                .second{
                    font-size: 64px;
                    font-family: "Bebas Neue", sans-serif;
                    line-height: 29px;
                    color:#2B2B2B;
                }
                
            }
        }
        .right_side{
            width: 50%;
            height: 100%;
            min-height: 154px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width:640px) {
                // padding-left: 20px;
                width: 100%;
                
            }
            div{

                .first{
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 29px;
                    color:#2B2B2B;
                    margin-bottom: 20px;
                }
                .second{
                    font-size: 64px;
                    font-family: "Bebas Neue", sans-serif;
                    line-height: 29px;
                    color:#2B2B2B;
                }
            }
        }
    }
}


.vouchers_container{
    max-width: 1440px;
    width: 100%;
    padding: 40px;
    padding-top: 80px;
    @media (max-width:540px) {
        padding: 25px;
    }

    .voucher_label{
        color: #2B2B2B;
        font-family: "Bebas Neue", sans-serif;
        line-height: 76px;
        font-size: 64px;
        margin-bottom: 20px;
    }
    .vouchers_list_container{
         display: flex;
         flex-wrap: wrap;
         justify-content: space-between;

         .preview{
            padding: 20px;
            border-radius: 10px;
            margin-bottom: 20px;
            border: 1px solid whitesmoke;
            // max-width: 400px;
            width: 32%;
            min-height: 239px;
            margin-bottom: 30px;
            @media (max-width:767px) {
                width: 48%;
            }
            @media (max-width:540px) {
                width: 100%;
            }
            .off{
                font-weight: 700;
                font-size: 28px;
                line-height: 40px;
            }
            .header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
                .pc{
                    color: #F0522B;
                    font-weight: 500;
                    font-size: 14px;

                }
                .dl{
                    color: #2B2B2B;
                    font-weight: 600;
                    font-size: 12px;
                }
            }
            .listing{
                font-size: 14px;
                margin-top: 10px;
                color: #B5B5B5;
            }
        }
    }
}
.sliderImgCon {
    width: 100%;
    height: 450px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
.modal_shesh{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 700px;
    //box-shadow: 2px 2px 20px white;
    // height: 750px;
    // padding: 20px;
    transform: translate(-50%, -50%);
    //background-color: white;
    max-width: 95%;
    border-radius: 20px;
    border-width: 0;
    .slick-next{
        right: 15px;
    }
    .slick-prev {
        left: 15px;
        z-index: 1;
    }
    .slick-dots{
        bottom: 15px;
        z-index: 1;
        li button::before {
            color: #FFF;
        }
        li.slick-active button::before {
            color: #f0522b;
        }
    }
    @media (max-width:640px) {
            // height: 400px;
           
    }
}