.footer_container{
        max-width: 1920px;
        display: flex;
        justify-content: center;
        width: 100%;
        // max-height:  1010px;
        // @media (max-width:980px) {
        //     max-width: 757px;
        // }
        @media (max-width:840px) {
            height: auto;
            background-size: cover;
        }
        .secondary_container{
            width: 1200px;
            height: 1010px;
            margin-top: 150px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media (max-width:840px) {
                height: auto;
                padding-bottom: 30px;
            }

            @media (max-width:767px) {
              margin-top: 50px;
            }
            .first{
                display: flex;
                align-items: center;
                
                @media (max-width:840px) {
                    flex-direction: column;
                }
                
                .left_side{
                    width: 50%;
                    height: 442px;
                    // max-height: 442px;
                    background-color: #020202;
                    // padding: 40px;
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;

                    @media (max-width:840px) {
                            width: 90%;
                            min-height:442px ;
                            max-height: auto;
                            height: auto;
                            border-radius: 0;
                            border-top-left-radius: 24px;
                            border-top-right-radius: 24px;

                    }   
              
                    .text_container{
                        padding: 40px;
                        .first{
                            color: #F0522B;
                            font-weight: 700;
                            font-size: 16px;
                            margin-bottom: 10px;
                            line-height: 16px;
                        //     @media (max-width:840px) {
                        //         text-align: start;
                                
                        //    }   
                  
                        }
                        .second_{
                            color: #FFFFFF;
                            font-family: "Bebas Neue", sans-serif;
                            font-weight: 400;
                            font-size: 42px;
                             line-height: 50px;
                             text-transform: capitalize;
                             
                        }
                        .third{
                            color: #FFFFFF;
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 29px;
                            margin-top: 20px;
                        }
                    }

                  
                }
                .right_side{
                    width: 50%;
                    height: 442px;
                    background-repeat: no-repeat;
                    position: relative;
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                    background-size: cover;
                    @media (max-width:840px) {
                        width: 90%;
                        border-radius: 0;
                        border-bottom-right-radius: 24px;
                        border-bottom-left-radius: 24px;

                   }   

                    .img_2{
                        position: absolute;
                        bottom: -55px;
                        right: 90px;
                        
                        @media (max-width:980px) {
                            right: 30px;
                            width: 95%;
                            bottom: 0;
                            
                        }

                        @media (max-width:840px) {
                        width: 70%;
                        border-radius: 0;
                        border-bottom-right-radius: 24px;
                        border-bottom-left-radius: 24px;

                       }   
                       @media (max-width:640px) {
                        width: 80%;
                       }   
                       @media (max-width:440px) {
                        width: 90%;
                        bottom: -20px;
                       }

                    }
                }
            }

            .second{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 90px;
                width: 100%;
                padding: 10px;
                .logo_container{
                    width: 201px;
                    height:87px;
                }
                .cutting_edge{
                    color: #8B8B8B;
                    font-size: 18px;
                    line-height: 21px;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    text-align: center;
                   
                }
                .list{
                    display:flex;
                    align-items: center;
                    width: 519px;
                    justify-content: space-between;
                    color: #2B2B2B;
                    font-size: 24px;
                    
                   .list_item{
                    font-weight: 700;
                    cursor: pointer;
                    @media (max-width:540px) {
                        width: 100%;
                        text-align: center;
                    }
                   }
                   @media (max-width:767px) {
                        width: 100%;
                        flex-wrap: wrap;
                        justify-content: center;
                   }
                }    

                .watermark{
                    color: #000000;
                    opacity: .5;
                    margin-top: 50px;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 22px;
                }
            }
          
        }
}

// @media  screen and (max-width: 1920px) {}
// @media  screen and (max-width: 1440px) {}
// @media  screen and (max-width: 1280px) {}
// @media  screen and (max-width: 1100px) {}
// @media  screen and (max-width: 1080px) {}
// @media  screen and (max-width: 980px) {}
// @media  screen and (max-width: 767px) {}
// @media  screen and (max-width: 640px) {}
// @media  screen and (max-width: 575px) {}
// @media  screen and (max-width: 440px) {}
// @media  screen and (max-width: 320px) {}
// @media  screen and (max-width: 280px) {}