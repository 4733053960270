*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Montserrat",sans-serif,sans-serif;
  }
  .openSans{
    font-family: "Open Sans", sans-serif !important;
  }
  .inter{
    font-family: "Inter", sans-serif !important;
  }
  body {
    margin: 0;
  }

  .react-calendar{
    width: 100%;
    padding: 20px;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
@media only screen and (max-width: 600px) {
  /* Make table cells stack on top of each other */
  tr {
      display: block;
  }
  /* Hide table header */
  th {
      display: none;
  }
  /* Display table rows as a flex container and make each cell full width */
  td {
      display: flex;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
  }
  /* Style even and odd rows differently */
  tr:nth-child(even) {
      background-color: #f2f2f2;
  }
}