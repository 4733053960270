.reservation_container{
    width: 100%;
    max-width: 1920px;  
    padding-top: 100px;
    max-width: 1440px;
    padding: 40px;

    .logo_section{

        @media (max-width:540px) {
           width: 80%;
           img{
            width: 100%;

           }
        }
    }

    @media (max-width:540px) {
        padding: 25px;
    }

    .content_container{
        width: 100%;
        display: flex;
        @media (max-width: 840px) {
                flex-direction: column;
        }

        .form_container{
            width: 50%;
            margin-bottom: 70px;
            display: flex;
            flex-direction: row;
            margin-top: 40px;
            flex-wrap: wrap;
            border: 1px solid lightgray;
            border-radius: 10px;
            justify-content: space-between;
            padding: 50px;
            height: auto;
            @media (max-width: 840px) {
                margin-bottom:0px;
           }
           @media (max-width: 540px) {
           padding: 0;
           }

            .fill_up_details{
                font-family: "Bebas Neue", sans-serif;
                font-size: 64px;
                line-height: 60.8px;
                @media (max-width: 540px) {
                    font-size: 64px;
                }
            }

            @media (max-width:640px) {
                margin-bottom: 0;
            }
        
            @media (max-width:1240px) {
                width: 100%;
                padding: 20px;
              
                
            }
        
            .input_custom{
                width: 47%;
                height: auto;
                @media (max-width:640px) {
                    width: 100%;
                }
            }
        
            .btn_kak{
                background-color:#F0522B ;
                color: white;
                width: 242px;
                margin-top: 20px;
                padding: 10px;
                @media (max-width:540px) {
                    width: 100%;
                }
            }
        
           
        }
        
        .calendar_container{
            width: 50%;
            padding: 50px;
            padding-left: 100px;
            @media (max-width: 840px) {
               padding-left: 50px;
               width: 100%;
               display: flex;
               justify-content: space-between;
            }
            @media (max-width:767px) {
                flex-direction: column;
                align-items: center;
                padding: 0;
            }   
            .first{
                margin-top: 30px;
                width: 100%;

                @media (max-width:767px) {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                
                } 
                
                .calendar_container2{
                    width: 100%;
                    display: flex;
                    @media (max-width:767px) {
                        justify-content: center;
                   
                     }  
                }
                .choose_date{
                    font-family: "Bebas Neue", sans-serif;
                    font-size: 40px;
                    @media (max-width:767px) {
                       text-align: center;
                  
                    }  
                }
            }
            .second{
                margin-top: 30px;
                // max-width: 420px;
                border-radius: 10px;
                width: 100%;
                height: 250px;
                overflow: auto;
                // max-width: 350px;
                @media (max-width:767px) {
                    width: 100%;
                }  
                .choose_date{
                    font-family: "Bebas Neue", sans-serif;
                    font-size: 40px;
                    @media (max-width:767px) {
                        text-align: center;
                   
                     }  
                }
            }
        }   
    }
}


