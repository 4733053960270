.queueng_container{
    padding: 20px;
    background-color: black;
    min-height: 100vh;
    position: relative;
    .confirm_reservation{
        color: white;
        font-weight: 600;
        font-size: 24px;
        margin-top: 10px;
      
    }
    .form{
        margin-top: 20px;
    }
}