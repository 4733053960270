.contact_main{
    display: flex;
    width:100%;
    flex-direction: column;
    align-items: center;
}
.contact_us_container{
        max-width: 1920px;
        width: 100%;
        display: flex;
        min-height: 700px;
        flex-wrap: wrap;
        position: relative;
        //  @media  {
            
        //  }
        @media (max-width:980px) {
            max-width: 757px;
        }
        .left{
            position: relative;
            width: 50%;

            @media (max-width:970px) {
                width: 100%;
                height: 700px;
            }
            .first_level{
                height: 458px;
                width: 395px;
                position: absolute;
                bottom: 20px;
                right: 0;
                border-radius: 24px;
                background-color: #E9E9E9;

                @media (max-width:540px) {
                    right: 0%;
                    width: 40%;
                    border-radius: 24px;
                    height: 400px;
                }
            }
            &::after{
                content: "";
                position:absolute;
                width: 650px;
                height: 519px;
                background-image: url("../../Assets/partners_logos/Rectangle_294.png");
                background-repeat: no-repeat;
                right: 80px;
                bottom: 100px;
                z-index: 3;
                @media (max-width:540px) {
                    left: 0;
                    width: 90%;
                    border-radius: 24px;
                }
            }
            &::before{
                content: "";
                position:absolute;
                width: 537px;
                border-top-right-radius: 24px;
                background-color: #210002;
                height: 350px;
                background-repeat: no-repeat;
                left: 0;
                top: 0;
                @media (max-width:540px) {
                    left: 0;
                    width: 50%;
                }
            }
        }
        .right{
            width: 50%;
            @media (max-width:970px) {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 40px;
                // height: 700px;
            }
          
            .desc_container{
                width: 50%;
                margin-left: 80px;
                margin-top: 110px;
                @media (max-width:970px) {
                    width: 80%;
                    margin: 0;
                    // height: 700px;
                }
              
                .first{
                    font-size: 64px;
                    line-height: 76px;
                    color: #2B2B2B;
                    font-family: "Bebas Neue", sans-serif;
                }
                .second{
                    color: #525C60;
                    font-size: 24px;
                    margin-top: 10px;
                    line-height: 29px;
                }
            }
            
        }   

}

.form_container{
    width: 1184px;
    height: 589px;
    margin-top: 90px;
    margin-bottom: 70px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width:980px) {
        max-width: 757px;
    }
    @media (max-width:640px) {
        margin-bottom: 250px;
    }

    @media (max-width:1240px) {
        width: 100%;
        padding: 20px;
      
        
    }

    .input_custom{
        width: 47%;
        height: auto;
        @media (max-width:640px) {
            width: 100%;
        }
    }

    .btn_kak{
        background-color:#F0522B ;
        color: white;
        width: 242px;
        margin-top: 20px;
        padding: 10px;
        @media (max-width:540px) {
            width: 100%;
        }
    }

   
}

