.loader_container{
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0,0,0,.8);
    overflow: hidden;
    z-index: 25;

    .lottie_container{
        width: 400px;
        height: 400px;
    }
}